import React from "react";
import { VALUE_TYPE_MAP } from "../../data/constants";
import { getFormattedDate } from "../../../../common/utils";
import { msToTime } from "../../../../common/Utilities";
import Status from "../../components/status";
import Comment from "../../components/comment";

export const getValueByType = (value, type, timeFormat, csvMode) => {
  switch (type) {
    case VALUE_TYPE_MAP.boolean:
      return typeof value !== "undefined" ? (value ? "Yes" : "No") : "-";
    case VALUE_TYPE_MAP.dateTime:
      return value ? getFormattedDate(value, timeFormat || "hh:mm A MMM Do") : "-";
    case VALUE_TYPE_MAP.date:
      return value ? getFormattedDate(value, timeFormat || "MMM Do") : "-";
    case VALUE_TYPE_MAP.duration:
      return value !== undefined ? msToTime(value) : "-";
    case VALUE_TYPE_MAP.status:
      return csvMode ? (
        value?.length ? (
          value.reduce((accumulator, eachStatus) => {
            return `${accumulator && `${accumulator}, `}${eachStatus.title}`;
          }, "")
        ) : (
          "-"
        )
      ) : (
        <Status statusList={value} />
      );
    case VALUE_TYPE_MAP.comment:
      return value ? <Comment comment={value} /> : "-";
    case VALUE_TYPE_MAP.number:
      return typeof value === "number" ? value.toLocaleString("us-en") : "-";
    default:
      return (Array.isArray(value) ? value.join(", ") : value) || "-";
  }
};

export const getTextByColumnType = (value, type) => {
  switch (type) {
    case VALUE_TYPE_MAP.comment:
      return value;
    default:
      return getValueByType(value, type);
  }
};
