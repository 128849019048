import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import Style from "./SelectedOptionsList.module.css";

export const SelectedOptionsList = ({ options, onRemoveOption }) => {
  return (
    <div className={Style.flex_row}>
      <div className={Style.selected_options_container}>
        {options.map((option) => {
          return (
            <div
              key={option.label}
              className={Style.option}
            >
              {option.label}
              <CloseIcon
                onClick={() => {
                  onRemoveOption(option);
                }}
                className={Style.close_icon}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

SelectedOptionsList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ).isRequired,
  onRemoveOption: PropTypes.func.isRequired
};
